import keys from 'lodash/keys';
import setLanguage from 'next-translate/setLanguage';
import { useRouter } from 'next/router';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FC, MouseEvent, useState } from 'react';
import DropdownIcon from 'public/assets/svg/chevron_down.svg';
import { setLocalStorage } from 'utils/store';

type Props = {
  isOnSideBar?: boolean;
};

const languageLogo = {
  en: 'england-flag',
  vi: 'vietnam-flag',
  id: 'indonesia-flag',
};

const MLanguageSelector: FC<Props> = ({ isOnSideBar }) => {
  const { locale } = useRouter();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleOpenDropdown = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const switchLanguage = (lang) => {
    setLanguage(lang);
    handleCloseDropdown();
    setLocalStorage('language', lang);
  };

  const handleExpandClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <div>
      <Box
        component="button"
        aria-haspopup="true"
        whiteSpace="nowrap"
        className="m-btn-menu u-text-uppercase"
        px={{ xs: 4, md: 10 }}
        height={36}
        fontSize={14}
        width={isOnSideBar ? '100%' : 'unset'}
        fontWeight={600}
        borderRadius={40}
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="#212121"
        bgcolor="white"
        border="1px solid #C8D0E0"
        onClick={isOnSideBar ? handleExpandClick : handleOpenDropdown}
      >
        <Box component="span" mr={{ xs: 2, md: 4 }}>
          <img src={`/assets/${languageLogo[locale]}.png`} width="16" height="10" alt="flag" />
        </Box>
        {locale}
        <Box component="span" ml={{ xs: 1, md: 4 }} width={20} height={20} className={anchorEl ? 'u-scale-y--1' : ''}>
          <DropdownIcon />
        </Box>
      </Box>
      {isOnSideBar ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List className="m-language-menu">
            {keys(languageLogo).map((key, index) => (
              <ListItem key={index} onClick={() => switchLanguage(key)}>
                <Box textAlign="center" width="100%" className="u-text-uppercase">
                  <Box component="span" mr={4}>
                    <img src={`/assets/${languageLogo[key]}.png`} width="16" height="10" alt="flag" />
                  </Box>
                  {key}
                </Box>
              </ListItem>
            ))}
          </List>
        </Collapse>
      ) : (
        <Menu
          className="m-language-menu"
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleCloseDropdown}
        >
          {keys(languageLogo).map((key, index) => (
            <MenuItem key={index} onClick={() => switchLanguage(key)}>
              <Box textAlign="center" width="100%" className="u-text-uppercase">
                <Box component="span" mr={4}>
                  <img src={`/assets/${languageLogo[key]}.png`} width="16" height="10" alt="flag" />
                </Box>
                {key}
              </Box>
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
};

export default MLanguageSelector;

import { FC } from 'react';
import Box from '@material-ui/core/Box';

interface MLoginPanelSliderItemProps {
  title: string;
  content: string;
  src: string;
}

const MLoginPanelSliderItem: FC<MLoginPanelSliderItemProps> = ({ title, content, src }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" className="c-login-panel-slider-item">
      <img src={src} alt="CV" width="auto" height="auto" className="c-login-panel-slider-item__img" />
      {title && (
        <Box component="h3" color="#fff" mt={10} pt={10}>
          {title}
        </Box>
      )}
      {content && (
        <Box className="o-body-regular3 c-login-panel-slider-item__content" color="#fff" mt={9}>
          {content}
        </Box>
      )}
    </Box>
  );
};

export default MLoginPanelSliderItem;
